<template>
  <div>
    <a-row class="mb-10 header" style="padding-bottom: 0">
      <a-col :span="12">
        <h2 class="upper mt-10">
          CARTA DE CRÉDITO ID
          {{ creditLetter.details.id }}
        </h2>
        <div class="txt name dotted-phrase" style="width: 340px">
          <div
            v-if="creditLetter.details.customer.person_type === 'Pessoa Física'"
          >
            {{ creditLetter.details.customer.first_name }}
            {{ creditLetter.details.customer.last_name }}
          </div>
          <div v-else>
            {{ creditLetter.details.customer.trading_name }}
          </div>
        </div>
      </a-col>

      <a-col class="cards" :span="12">
        <a-row :gutter="20">
          <a-col :span="8">
            <div class="box total">
              <div class="name">TOTAL</div>
              <div class="number">
                {{ creditLetter.details.currency | formatCurrency }}
                {{ creditLetter.details.value | formatPrice }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box available">
              <div class="name">DISPONÍVEL</div>
              <div class="number">
                {{ creditLetter.details.currency | formatCurrency }}
                {{ creditLetter.details.available_value | formatPrice }}
              </div>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="box used">
              <div class="name">UTILIZADO</div>
              <div class="number">
                {{ creditLetter.details.currency | formatCurrency }}
                {{ creditLetter.details.spent_value | formatPrice }}
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import reusableThings from "@/mixins/general/reusableThings";
export default {
  name: "CreditLetterModalHeader",
  props: {
    creditLetter: Object,
  },
  mixins: [reusableThings],
};
</script>
